<template>
  <main class="kb-main" id="kb-myclass-notice">
    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-top -->
      <div class="header-top main-component">
        <div class="header-column">
          <a href="javascript:" @click="router.push({name: 'LiveCalendarMng'})" class="page-nav">
            <i class="icon-kb-nav-arrow"></i>
            <span class="text">이전</span>
          </a>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">라이브편성표 공지사항</h2>
      </div>
    </div>
    <!-- //main-header -->

    <!-- main-content -->
    <div class="main-content min-component">
      <div class="search-container">
        <div class="list-top">
          <div class="top-column ps-6">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="공지사항 검색"
                       v-model="search.title"
                       @keyup.enter="clickSearch"
                />
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="items && items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" :key="idx" class="board-list-item">
              <article class="board-row">
                <router-link :to="{path: '/live/notice/'+item.distCrseSn+'/'+item.lrnPostSn}" class="board-link"></router-link>
                <div class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
                <div class="board-column column-title">
                  <span class="title">{{item.title}}</span>
                  <i v-if="item.newNotice === 'Y'" class="icon-new"></i>
                </div>
                <div class="board-column column-date"><span class="text">{{ getDateFormat('yyyy-MM-dd', item.regDt)}}</span></div>
                <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
              </article>
            </li>
          </ul>
         <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getCdNm,
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {ACT_BOARD_GET_NTC_LIST} from "@/store/modules/live/live";

export default {
  name: 'LiveNotice',
  components: {CommonPaginationFront},
  setup(){
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const distCrseSn = computed(() => route.params.distCrseSn);
    const search = reactive({
      title:'',
      distCrseSn: distCrseSn.value,
      divCdDcd: '2013001'
    });

    const ntcCateCdDcd = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2022') === 0));

    const cateNm = computed(() => {
      if (search.ntcCateCdDcd && search.ntcCateCdDcd.length > 0) return getCdNm(store, search.ntcCateCdDcd);
      return '카테고리';
    });

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpNoticeList = () => {
      store.dispatch(`live/${ACT_BOARD_GET_NTC_LIST}`, {
        title: search.title,
        distCrseSn: search.distCrseSn,
        divCdDcd: search.divCdDcd,

        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newNotice = 'Y';
            else x.newNotice = 'N';
          })
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.title) query.title = search.title;
      if(search.ntcCateCdDcd) query.ntcCateCdDcd = search.ntcCateCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const selectCate = (dcd) => {
      if (dcd === '2022001') dcd =  '';
      search.ntcCateCdDcd = dcd;
      pagingFunc(1, 10);
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HelpNotice', paging, search, true);
      getHelpNoticeList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HelpNotice', paging, search, true);
    });

    onMounted(() => {
      getHelpNoticeList();
    });

    return{
      isCategoryActive,
      items,
      paging,
      search,
      router,
      pagingFunc,
      clickSearch,
      getDateFormat,

      ntcCateCdDcd,
      selectCate,
      cateNm,
    }

  }
}
</script>